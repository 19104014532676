<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="visible"
    :title="kjbbType == 'print' ? '会计报表打印' : '会计报表导出'"
    width="650px"
  >
    <el-form>
      <el-form-item label="所属期:">
        <div>
          <el-date-picker
            v-model="startPeriod"
            type="month"
            placeholder="选择月"
            size="small"
            value-format="YYYYMM"
          >
          </el-date-picker>
          --
          <el-date-picker
            v-model="endPeriod"
            type="month"
            placeholder="选择月"
            size="small"
            value-format="YYYYMM"
          >
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item label="是否打印封面:">
        <el-switch v-model="fm" :active-value="1" :inactive-value="0" />
      </el-form-item>
      <el-form-item label="金额是否带千分符：">
        <el-switch v-model="thousandSep" />
      </el-form-item>
    </el-form>
    <el-checkbox
      :indeterminate="isIndeterminate"
      v-model="checkAll"
      @change="handleCheckAllChange"
      >全选</el-checkbox
    >
    <el-checkbox-group
      v-if="accountSystemArr[0] == '民办非'"
      v-model="checkedCities2"
      @change="handleCheckedCitiesChange"
    >
      <el-checkbox v-for="city in cityOptions2" :label="city" :key="city">{{
        city
      }}</el-checkbox>
    </el-checkbox-group>

    <el-checkbox-group
      v-else-if="accountSystemArr[0] == '农业合作社'"
      v-model="checkedCities3"
      @change="handleCheckedCitiesChange"
    >
      <el-checkbox v-for="city in cityOptions3" :label="city" :key="city">{{
        city
      }}</el-checkbox>
    </el-checkbox-group>

    <el-checkbox-group
      v-else
      v-model="checkedCities"
      @change="handleCheckedCitiesChange"
    >
      <el-checkbox v-for="city in cityOptions" :label="city" :key="city">{{
        city
      }}</el-checkbox>
    </el-checkbox-group>
    <el-form v-if="kjbbType == 'print'">
      <el-form-item label="上偏移量(mm)：">
        <el-input
          type="number"
          v-model.number="top"
          placeholder="请输入"
          size="small"
          style="width: 200px"
        ></el-input>
      </el-form-item>
      <el-form-item label="左偏移量(mm)：">
        <el-input
          type="number"
          v-model.number="right"
          placeholder="请输入"
          size="small"
          style="width: 200px"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogkjbbVisible = false"
          >取消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="kjbbDaYin"
          :loading="kjbbLoading"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { bulkPrintMq } from "@/api/printSet";
import { bulkExportMq } from "@/api/export";
export default {
  name: "",
  components: {},
  props: {
    period: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      visible: false,
      cityOptions: [
        "资产负债表",
        "利润表(季报)",
        "利润表(月报)",
        "利润统计表",
        "现金流量表(月报)",
        "现金流量表(季报)",
      ],
      cityOptions2: ["资产负债表", "业务活动表(月报)", "业务活动表(季报)"],
      cityOptions3: ["资产负债表", "盈余及盈余分配表", "成员权益变动表"],

      checkAll: false,
      checkedCities: ["资产负债表", "利润表(月报)", "现金流量表(月报)"],
      checkedCities2: ["资产负债表", "业务活动表(月报)"],
      checkedCities3: ["资产负债表", "盈余及盈余分配表"],

      isIndeterminate: true,
      kjbbType: "",
      accountSystemArr: [],
      startPeriod: "",
      endPeriod: "",
      kjbbLoading: false,
      top: 0,
      right: 20,
      comIds: [],
      fm: 0,
      thousandSep: true,
    };
  },
  methods: {
    init(arr, type) {
      this.getAccountArr(arr);
      let fail = arr.find(
        (v) =>
          this.accountSystemArr[0] == "民办非" &&
          v.accountSystem != this.accountSystemArr[0]
      );
      if (fail) {
        this.$qzfMessage(
          "存在民办非的公司，民办非请选择一致的会计制度的公司",
          1
        );
        return;
      }
      let fail1 = arr.find(
        (v) =>
          this.accountSystemArr[0] == "农业合作社" &&
          v.accountSystem != this.accountSystemArr[0]
      );
      if (fail1) {
        this.$qzfMessage(
          "存在农业合作社的公司，农业合作社请选择一致的会计制度的公司",
          1
        );
        return;
      }
      this.kjbbType = type;
      this.visible = true;
    },
    kjbbDaYin() {
      let bookNames = [];
      if (this.accountSystemArr[0] == "民办非") {
        this.checkedCities = this.checkedCities2;
      } else if (this.accountSystemArr[0] == "农业合作社") {
        this.checkedCities = this.checkedCities3;
      }
      if (this.checkedCities.length == 0) {
        this.$qzfMessage("请至少选择一个", 1);
        return;
      }
      this.kjbbLoading = true;
      this.checkedCities.map((v) => {
        if (v == "资产负债表") {
          bookNames.push("kj_zcfz");
        } else if (
          v == "利润表(季报)" ||
          v == "业务活动表(季报)" ||
          v == "盈余及盈余分配表"
        ) {
          bookNames.push("kj_lrb_q");
        } else if (
          v == "利润表(月报)" ||
          v == "业务活动表(月报)" ||
          v == "成员权益变动表"
        ) {
          bookNames.push("kj_lrb_m");
        } else if (v == "利润统计表") {
          bookNames.push("kj_lrb_y");
        } else if (v == "现金流量表(月报)") {
          bookNames.push("kj_xjllb_m");
        } else if (v == "现金流量表(季报)") {
          bookNames.push("kj_xjllb_q");
        }
      });
      if (this.kjbbType == "print") {
        if (this.top > 50) {
          this.$qzfMessage("上偏移量不可超过50", 1);
          this.kjbbLoading = false;
          return;
        }
        if (this.right > 25) {
          this.$qzfMessage("左偏移量不可超过25", 1);
          this.kjbbLoading = false;
          return;
        }
        let dayinParam = {
          comIds: this.comIds,
          period: this.period,
          mqType: "kjbb",
          startPeriod: this.startPeriod,
          endPeriod: this.endPeriod,
          bookNames: bookNames,
          top: this.top,
          right: this.right,
          zip: 1,
          from: "kjbb",
          merge: 1,
          fm: this.fm,
          thousandSep: this.thousandSep,
        };
        bulkPrintMq(dayinParam).then((res) => {
          this.visible = false;
          this.kjbbLoading = false;
          if (res.data.data.msg) {
            this.$qzfMessage(res.data.data.msg);
          } else {
            window.open(res.data.data.path);
          }
        });
      } else {
        let daochuParam = {
          comIds: this.comIds,
          mqType: "kjbb",
          startPeriod: this.startPeriod,
          endPeriod: this.endPeriod,
          itemNames: bookNames,
          period: this.period,
        };
        bulkExportMq(daochuParam).then((res) => {
          this.visible = false;
          this.kjbbLoading = false;
          if (res.data.msg == "success") {
            if (res.data.data.msg) {
              this.$qzfMessage(res.data.data.msg);
            } else {
              window.open(res.data.data.path);
            }
          }
        });
      }
    },
    // 选择打印财务报表的
    handleCheckAllChange(val) {
      if (this.accountSystemArr[0] == "民办非") {
        this.checkedCities2 = val ? this.cityOptions2 : [];
      } else if (this.accountSystemArr[0] == "农业合作社") {
        this.checkedCities3 = val ? this.cityOptions3 : [];
      } else {
        this.checkedCities = val ? this.cityOptions : [];
      }
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cityOptions.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cityOptions.length;
    },
    getAccountArr(arr) {
      this.accountSystemArr = [];
      this.comIds = [];
      arr.map((v) => {
        if (v.accountSystem == "民办非" || v.accountSystem == "农业合作社") {
          this.accountSystemArr.unshift(v.accountSystem);
        } else {
          this.accountSystemArr.push(v.accountSystem);
        }
        this.comIds.push(v.comId);
      });
    },
  },
};
</script>
