<template>
    <div class="content_data">
    <div
      style="display: flex; align-items: center;width:97%;margin: 0 auto;height:70px;padding-left: 3%;"
    >
      <div class="each">
        <div class="icon_bg">
          <i
            class="iconfont icon-gongsi1"
            style=" font-size: 18px;color:#17a2b8"
          ></i>
        </div>
        <div style="margin-left:3%;cursor: pointer;">
          <div style="margin-bottom:10px">企业</div>
          <div>
            <span style="font-size: 16px;font-weight: 600;">{{ total }}</span>&nbsp;家
          </div>
        </div>
      </div>
      <div class="each">
        <div>
          <el-progress type="circle" :percentage="cbnb ? parseInt((cbnb/total)*100) : 0" color="#67c23a" />
        </div>
        <div style="margin-left:3%;cursor: pointer;">
          <div style="margin-bottom:10px">财报年报完成数</div>
          <div>
            <span style="font-size: 16px;font-weight: 600;">{{ cbnb }}</span
            ><span class="count"
              >未完成
              <span style="font-size: 14px;font-weight: 600;color: #333;"
                >{{ Number(total) - Number(cbnb) }}</span
              ></span
            >
          </div>
        </div>
      </div>
      <div class="each">
        <div>
          <el-progress type="circle" :percentage="hsqj ? parseInt((hsqj/total)*100) : 0" color="#67c23a" />
        </div>
        <div style="margin-left:3%;cursor: pointer;">
          <div style="margin-bottom:10px">汇算清缴完成数</div>
          <div>
            <span style="font-size: 16px;font-weight: 600;">{{ hsqj }}</span
            ><span class="count"
              >未完成
              <span style="font-size: 14px;font-weight: 600;color: #333;"
                >{{ Number(total) - Number(hsqj) }}</span
              ></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main">
    <!-- <div style="font-size:12px;color:red;margin-bottom: 10px;">
      （汇算清缴鲸算盘系统暂时不可申报，申报开放时间另行通知）
    </div> -->
    <div class="top_btns">
      <div>
        <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
        <el-date-picker v-model="listQuery.year" type="year" value-format="YYYY" placeholder="选择年份"  @change="getList" size="small" style="width:70px" :clearable="false"/>
        <!-- <el-date-picker @change="getList" :clearable="false" v-model="listQuery.period" type="month" value-format="YYYYMM" size="small" placeholder="日期" style="width: 160px;margin-right:5px;"></el-date-picker> -->
        <el-input placeholder="请输入公司名称/手机号/编号" v-model="listQuery.name" style="width: 160px;margin-left:5px" class="filter-item" size="small" @keyup.enter="getList" clearable/>
        <el-button class="filter-item1" type="primary" size="small" @click="getList" style="margin-right:5px">
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel">
          <el-scrollbar :height="350">
            <el-form style="width:650px" label-width="130px" :inline="true">
            <el-form-item label="税局："> 
              <selectcity v-model:citys="listQuery.districtCode" style="width:140px"></selectcity>
            </el-form-item>
            <el-form-item label="纳税人类型：" >   
              <selecttaxtype v-model:type="listQuery.type" style="width:140px"></selecttaxtype>
            </el-form-item>
            <el-form-item label="公司类型：" > 
              <el-select v-model="listQuery.companyType" placeholder="请选择公司类型" size="small" filterable clearable style="width:140px;">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
              <el-form-item label="选择人员：" >
                <selectuser code="bookkeeping" v-model:userId="listQuery.userId" style="width:140px"></selectuser>
              </el-form-item>
              <el-form-item label="授权状态：" >   
                <selectAccreditStatus v-model:type="listQuery.keepStatus" style="width:140px"></selectAccreditStatus>
              </el-form-item>
              <el-form-item label="是否财务工厂公司：" > 
                <el-select v-model="listQuery.automatic1" placeholder="请选择公司类型" size="small" filterable clearable style="width:140px;">
                  <el-option v-for="item in optionsAutomatic1" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="财务报表取数结果：">
                <selectYearAcessStatus v-model:allStatus="listQuery.allStatus1"></selectYearAcessStatus>
              </el-form-item>
              <el-form-item label="财务报表申报结果：">
                <selectYearBusinessAcessStatus v-model:allStatus="listQuery.allStatus2"></selectYearBusinessAcessStatus>
              </el-form-item>
              <el-form-item label="汇算清缴采集结果：">
                <selectYearBusinessCjStatus v-model:allStatus="listQuery.allStatus3"></selectYearBusinessCjStatus>
              </el-form-item>
              <el-form-item label="汇算清缴取数结果：">
                <selectYearAcessStatus v-model:allStatus="listQuery.allStatus4"></selectYearAcessStatus>
              </el-form-item>
              <el-form-item label="汇算清缴申报结果：">
                <selectYearBusinessAcessStatus v-model:allStatus="listQuery.allStatus5"></selectYearBusinessAcessStatus>
              </el-form-item>
            </el-form>
          </el-scrollbar>
       
        </search>
        <!-- <span class="hint"><i class="iconfont icon-gantanhao1"></i
          >暂未开放此功能</span> -->
      </div>
      <div>
        <el-button size="small" type="primary" icon="Aim" :disabled="listQuery.year != lastYear" plain @click="batchCwbbQs"  v-if="$buttonStatus('hsqj_dyb')" style="padding: 5px 8px;">财报取数</el-button>
        <el-button size="small" type="success" icon="Operation" :disabled="listQuery.year != lastYear" plain @click="batchCwbbSb" v-if="$buttonStatus('hsqj_deb')" class="operation_btn">财报申报</el-button>
        <el-button size="small" type="primary" icon="Postcard" :disabled="listQuery.year != lastYear" plain @click="batchCollect" v-if="$buttonStatus('hsqj_dsanb')" class="operation_btn">汇算清缴采集</el-button>
        <el-button size="small" type="primary" icon="SetUp" :disabled="listQuery.year != lastYear" plain @click="batchQs" v-if="$buttonStatus('hsqj_dsb')" class="operation_btn">汇算清缴取数</el-button>
        <el-button size="small" type="success" icon="Setting" :disabled="listQuery.year != lastYear" plain @click="batchSb" v-if="$buttonStatus('hsqj_dwb')" class="operation_btn">汇算清缴申报</el-button>
        <el-button size="small" type="primary" icon="Coin" :disabled="listQuery.year != lastYear" plain @click="batchKk" v-if="$buttonStatus('hsqj_dwb')" class="operation_btn">汇算清缴缴款</el-button>
      </div>
    </div>
    <el-table :data="list" style="width: 100%" :height="contentStyleObj" v-loading="loading" stripe @select="handleSelectionChange" @sort-change="sortChange" ref="tableScroll" id="tableLazyLoad" @select-all="handleSelectionChangeAll">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" prop="id" type="selection" width="50" />
      <el-table-column label="编号" align="center" width="80" fixed="left" prop="sort" sortable="custom">
        <template #default="scope">
          <TableSortCell :row="scope.row" idKey="comId" />
        </template>
      </el-table-column>
      <el-table-column prop="name" label="公司名称" fixed min-width="240" >
        <template #default="scope">
          <TagNameCopy :row="scope.row"  idKey="comId" ></TagNameCopy>
        </template>
      </el-table-column>
      <el-table-column prop="districtName" label="税局" width="65" align="center">
        <template #default="scope">
          <span>{{ $cityFilter(scope.row.district) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="财务报表">
        <el-table-column prop="state" label="取数（第一步）"  width="190">
          <template #default="scope">
            <div class="item_icon">
              <p>
                <i :class="$yearAcessIconStatus(scope.row.taskStatus1)"></i>
                <el-tooltip effect="dark" :disabled="!scope.row.errLog1 && !scope.row.bussinessLog" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.bussinessLog?scope.row.bussinessLog:'' + scope.row.errLog1?scope.row.errLog1:''"></div>
                  </template>
                  <span :class="$yearAcessWordStatus(scope.row.taskStatus1)">{{$yearAcessStatus(scope.row.taskStatus1)}} <i class="iconfont icon-wenhao" style="color:red" v-if="(scope.row.errLog1 || scope.row.bussinessLog) && scope.row.taskStatus1 == 3">
              </i> </span>
                </el-tooltip>
                <el-button type="text" size="small" @click="cwbbAcess(scope.row)" v-if="$buttonStatus('hsqj_dyb')">取数</el-button>
                <el-button @click="checkCwbbYear(scope.row)" :disabled="scope.row.taskStatus1 != 3" size="small" type="text" style="margin-left: 0;">查看</el-button>

              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="city" label="申报（第二步）"   min-width="210">
          <template #default="scope">
            <div >
              <p class="item_icon">
                <el-tooltip class="item" effect="dark" :disabled="!scope.row.errLog2 && !scope.row.bussinessLog2" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.bussinessLog2?scope.row.bussinessLog2:'' + scope.row.errLog2?scope.row.errLog2:''"></div>
                  </template>
                  <p>
                    <span v-if="scope.row.image2">
                      <i class="iconfont icon-picture" style="color:red;font-size:13px;" v-if="scope.row.bussinessStatus2 == 3" @click="openImage2(scope.row,scope.$index)"></i>
                      <i class="iconfont icon-picture" style="color:#67c23a;font-size:13px;" v-if="scope.row.bussinessStatus2 == 2 ||scope.row.bussinessStatus2 == 6 ||scope.row.bussinessStatus2 == 20  || scope.row.bussinessStatus2 == 22" @click="openImage2(scope.row,scope.$index)"></i>
                      <i class="iconfont icon-picture" style="color:#39b0d2;font-size:13px;" v-if="scope.row.bussinessStatus2 == 4 || scope.row.bussinessStatus2 == 5 || scope.row.bussinessStatus2 == 21 || scope.row.bussinessStatus2 == 40" @click="openImage2(scope.row,scope.$index)"></i>
                    </span>
                    <i v-if="!scope.row.image2" :class="$batchSendIconSb(scope.row.taskStatus2, scope.row.bussinessStatus2)"></i>
                    <span :class="$batchSendWordSb(scope.row.taskStatus2, scope.row.bussinessStatus2)">{{$batchSendYearSb(scope.row.taskStatus2,scope.row.bussinessStatus2)}}<i class="iconfont icon-wenhao" style="color:red;margin-left:4px" v-if="(scope.row.errLog2 || scope.row.bussinessLog2) && scope.row.taskStatus2 == 3">
                  </i></span>
                  </p>
                </el-tooltip>
                <el-button type="text" size="small" @click="cwbbDeclare(scope.row)" :disabled="scope.row.taskStatus1 != 3 || listQuery.year != lastYear" v-if="$buttonStatus('hsqj_deb')">申报</el-button>
              </p>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="汇算清缴">
        <el-table-column prop="state" label="采集（第三步）"  min-width="210">
          <template #default="scope">
            <div class="item_icon">
              <p>
                <i :class="$setUpStatusJzCjIcon(scope.row.taskStatus3, scope.row.bussinessStatus3)"></i>
                <el-tooltip class="item" effect="dark" :disabled="!scope.row.errLog3 && !scope.row.bussinessLog3" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.bussinessLog3?scope.row.bussinessLog3:'' + scope.row.errLog3?scope.row.errLog3:''"></div>
                  </template>
                  <span :class="$setUpStatusGsWordZf(scope.row.taskStatus3, scope.row.bussinessStatus3)">{{$setUpStatusJzCj(scope.row.taskStatus3,scope.row.bussinessStatus3)}}<i class="iconfont icon-wenhao" style="color:red;margin-left:4px" v-if="(scope.row.errLog3 || scope.row.bussinessLog3) && scope.row.taskStatus3 == 3">
              </i></span>
                </el-tooltip>
                <el-button type="text" size="small" @click="yearCollect(scope.row)" :disabled="scope.row.taskStatus2 != 3 || (scope.row.taskStatus2 == 3 && scope.row.bussinessStatus2 == 3)  || listQuery.year != lastYear" v-if="$buttonStatus('hsqj_dsanb')">采集</el-button>
                <el-button @click="checkTable(scope.row)" :disabled="scope.row.bussinessStatus3 != 2" size="small" type="text" style="margin-left: 0;">查看</el-button>

              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="city" label="取数（第四步）"  width="190">
          <template #default="scope">
            <div class="item_icon">
              <p>
                <i :class="$yearAcessIconStatus(scope.row.taskStatus4)"></i>
                <el-tooltip class="item" effect="dark" :disabled="!scope.row.errLog4 && !scope.row.bussinessLog" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.bussinessLog?scope.row.bussinessLog:'' + scope.row.errLog4?scope.row.errLog4:''"></div>
                  </template>
                  <span :class="$yearAcessWordStatus(scope.row.taskStatus4)">{{$yearAcessStatus(scope.row.taskStatus4)}}<i class="iconfont icon-wenhao" style="color:red;margin-left:4px" v-if="(scope.row.errLog4 || scope.row.bussinessLog) && scope.row.taskStatus4 == 3">
              </i></span>
                </el-tooltip>
                <el-button type="text" size="small" @click="yearAcess(scope.row)" :disabled="scope.row.taskStatus3 != 3" v-if="$buttonStatus('hsqj_dsb')">取数</el-button>
                <el-button  @click="checkQysdYear(scope.row)" size="small" type="text" :disabled="scope.row.taskStatus4 != 3" style="margin-left: 0;">查看</el-button>
              </p>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="汇算清缴申报">
        <el-table-column prop="state" label="申报（第五步）"  min-width="210">
          <template #default="scope">
            <div >
              <p class="item_icon">
                <el-tooltip class="item" effect="dark" :disabled="!scope.row.errLog5 && !scope.row.bussinessLog5" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.bussinessLog5?scope.row.bussinessLog5:'' + scope.row.errLog5?scope.row.errLog5:''"></div>
                  </template>
                  <p>
                    <span v-if="scope.row.image5">
                      <i class="iconfont icon-picture" style="color:red;font-size:13px;" v-if="scope.row.bussinessStatus5 == 3" @click="openImage5(scope.row,scope.$index)"></i>
                      <i class="iconfont icon-picture" style="color:#67c23a;font-size:13px;" v-if="scope.row.bussinessStatus5 == 2 ||scope.row.bussinessStatus5 == 6 ||scope.row.bussinessStatus5 == 20  || scope.row.bussinessStatus5 == 22" @click="openImage5(scope.row,scope.$index)"></i>
                      <i class="iconfont icon-picture" style="color:#39b0d2;font-size:13px;" v-if="scope.row.bussinessStatus5 == 4 || scope.row.bussinessStatus5 == 5 || scope.row.bussinessStatus5 == 21 || scope.row.bussinessStatus5 == 60" @click="openImage5(scope.row,scope.$index)"></i>
                    </span> 
                    <i v-if="!scope.row.image5" :class="$batchSendIconSb(scope.row.taskStatus5, scope.row.bussinessStatus5)"></i>
                    <span :class="$batchSendWordSb(scope.row.taskStatus5, scope.row.bussinessStatus5)" style="border: 0;font-size: 13px;">{{$batchSendYearSb(scope.row.taskStatus5,scope.row.bussinessStatus5)}}<i class="iconfont icon-wenhao" style="color:red;margin-left:4px" v-if="(scope.row.errLog5 || scope.row.bussinessLog5) && scope.row.taskStatus5 == 3">
                    </i></span>
                  </p>
                </el-tooltip>
                <el-button type="text" size="small" @click="yearDeclare(scope.row)" :disabled="scope.row.taskStatus4 != 3 || listQuery.year != lastYear" v-if="$buttonStatus('hsqj_dwb')">申报</el-button>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="缴款（第六步）"  min-width="210">
          <template #default="scope">
            <div >
              <p class="item_icon">
                <el-tooltip class="item" effect="dark" :disabled="!scope.row.errLog6 && !scope.row.bussinessLog6" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.bussinessLog6?scope.row.bussinessLog6:'' + scope.row.errLog6?scope.row.errLog6:''"></div>
                  </template>
                  <p>
                    <span v-if="scope.row.image6">
                      <i class="iconfont icon-picture" style="color:red;font-size:13px;" v-if="scope.row.bussinessStatus6 == 3" @click="openImage6(scope.row,scope.$index)"></i>
                      <i class="iconfont icon-picture" style="color:#67c23a;font-size:13px;" v-else-if="scope.row.bussinessStatus6 == 2 ||scope.row.bussinessStatus6 == 13" @click="openImage6(scope.row,scope.$index)"></i>
                      <i class="iconfont icon-picture" style="color:#39b0d2;font-size:13px;" v-else @click="openImage6(scope.row,scope.$index)"></i>
                    </span> 
                    <i v-if="!scope.row.image6" :class="$batchSendIconSb(scope.row.taskStatus6, scope.row.bussinessStatus6)"></i>
                    <span :class="$batchSendWordKk(scope.row.taskStatus6, scope.row.bussinessStatus6)">{{$batchSendKk(scope.row.taskStatus6,scope.row.bussinessStatus6)}}<i class="iconfont icon-wenhao" style="color:red;margin-left:4px" v-if="(scope.row.errLog6 || scope.row.bussinessLog6) && scope.row.taskStatus6 == 3">
                    </i></span>
                  </p>
                </el-tooltip>
                <el-button type="text" size="small" @click="yearKk(scope.row)" :disabled="scope.row.taskStatus5 != 3 || listQuery.year != lastYear" v-if="$buttonStatus('hsqj_dwb')">缴款</el-button>
              </p>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" type="yearSb"/>
    </div>

    <checkYearTable ref="checkYearTable" :yearTime="this.listQuery.year" :name="this.listQuery.comName"></checkYearTable>
    <checkCwbbYear ref="checkCwbbYear" :yearTime="this.listQuery.year" :name="this.listQuery.comName"></checkCwbbYear>
    <checkQysdYear ref="checkQysdYear" :yearTime="this.listQuery.year" :name="this.listQuery.comName"></checkQysdYear>
    <sbImage ref="sbImage" />
  </div>
  </template>

<script>
import { companyYearList } from "@/api/company"
import { yearSave} from "@/api/year";
import selectYearBusinessAcessStatus from "./components/select/selectYearBusinessAcessStatus.vue"
import selectYearBusinessCjStatus from "./components/select/selectYearBusinessCjStatus"
import selectYearAcessStatus from "./components/select/selectYearAcessStatus.vue"
import checkYearTable  from "./components/checkYearTable.vue"
import checkCwbbYear  from "./components/checkCwbbYear.vue"
import checkQysdYear  from "./components/checkQysdYear.vue"
import { sendTask } from "@/api/collection"
import selectcity from "@/components/Screening/selectcity";
import selecttaxtype from "@/components/Screening/selecttaxtype";
import selectAccreditStatus from "@/components/Screening/selectAccreditStatus";
import sbImage from '@/components/sbImage'
import { COMPANY_TYPE_OPTION } from '@/utils/commonData'
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import {getLastYear} from '@/utils'
export default {
  name: 'yearSb',
  components:{
    selectYearBusinessAcessStatus,
    selectYearBusinessCjStatus,
    selectYearAcessStatus,
    checkYearTable,
    checkCwbbYear,
    checkQysdYear,
    selectcity,
    selecttaxtype,
    selectAccreditStatus,
    sbImage,
    TagNameCopy,
    TableSortCell
  },

  data() {
    return {
      list:[],
      listQuery:{
        limit:20,
        page:1,
        name:'',
        year:getLastYear(),
        comName:'',
        allStatus1:[],
        allStatus2:[],
        allStatus3:[],
        allStatus4:[],
        allStatus5:[],
        automatic1:0
      },
      total:0,
      contentStyleObj:{},
      loading:false,
      sels:[],
      ids:[],
      cbnb:0,
      hsqj:0,
      totalPage:0,
      pageSize:35,
      tableData:[],
      currentPage:1,
      allChecked:false,
      options:COMPANY_TYPE_OPTION,
      optionsAutomatic1: [
        { label: "全部", value: 0 },
        { label: "是", value: 1 },
        { label: "否", value: 2 },
      ],
      lastYear:getLastYear(),
      taskAddress:['51','15','62','13','21','36','44','32','41']
    };
  },
  created(){
    // this.init()
    this.listQuery.limit = localStorage.getItem('yearSb') ? localStorage.getItem('yearSb')*1 : 20
    this.getList()
    this.contentStyleObj= this.$getHeight(270)
  },

  mounted() {
    
  },

  methods: {
    // 重置
    cancel(){
      let originLimit =this.listQuery.limit
      this.listQuery={
        limit:originLimit,
        page:1,
        name:'',
        year:getLastYear(),
        comName:'',
        allStatus1:[],
        allStatus2:[],
        allStatus3:[],
        allStatus4:[],
        allStatus5:[],
        districtCode:null,
        type:null,
        userId:null,
        keepStatus:null,
        automatic1:0
      }
      this.getList()
    },
    init(){
      this.$alert('<strong>该功能暂内测中,现无法使用</strong>', {
        confirmButtonText: '确定',
        dangerouslyUseHTMLString: true
      })
    },
    getList(){
      this.loading = true
      companyYearList(this.listQuery).then(res=>{
        this.loading = false
        this.cbnb = res.data.data.cbnb ? res.data.data.cbnb : 0
        this.hsqj = res.data.data.hsqj  ? res.data.data.hsqj : 0
        this.tableData = res.data.data.list ? res.data.data.list : []
        this.total = res.data.data.total
        this.setScroll()
      }) 
    },
    setScroll(){
      //先重置状态和数组
      this.allChecked = false
      this.sels = []
      this.ids = []
      //计算滚动页数
      this.totalPage = this.tableData.length/this.pageSize
      if(this.tableData.length % this.pageSize == 0){
        this.totalPage = this.tableData.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.tableData.length/this.pageSize) + 1
      }
      this.list = this.tableData.slice(0,this.currentPage*this.pageSize)
      this.lazyLoading()
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if(that.allChecked && that.sels.length == that.tableData.length){
              that.$refs.tableScroll.toggleAllSelection()
              that.allChecked = true
            }else if(that.sels.length != 0){
              that.sels.map(v=>{
                that.list.map(item=>{
                  if(item.comId == v.comId){
                    that.$nextTick(()=>{
                     that.$refs.tableScroll.toggleRowSelection(item,true)
                    })
                  }
                })
              })
            }
          }
        }
      });
    },
    // 表格排序
    sortChange(data) {
      if(data.order == "descending"){
        this.listQuery.desc = 1
      }else{
        this.listQuery.desc = 0
      }
      this.getList()
    },
    // 财务报表
    // 取数
    cwbbAcess(row){
      let param = {
        type: 1,
        comIds: [row.comId],
        period:this.listQuery.year
      }
      this.loading = true
      yearSave(param).then(res => {
        this.loading = false

        if(res.data.msg == 'success'){
          this.$message({
            message: "取数成功",
            type: "success"
          });
          this.getList()
        }
      });
    },
    // 取数完查看
    checkCwbbYear(row){
      this.listQuery.comName=row.name
      this.$refs.checkCwbbYear.getList(row)
    },
    // 申报
    cwbbDeclare(row){
      if(row.district != 'zhejiang' && row.district != 'guangdong' && row.district != 'tianjin' && row.district != 'hubei' && row.loginMethod == '新版登录' && row.yzmType == 1 && row.keepStatus != 1){
        this.$qzfMessage('未授权登录，请先授权再发起任务！',1)
        return
      }
      // let code = row.districtCode.substring(0, 2)
      // if(!this.taskAddress.includes(code) ){
      //   this.$qzfMessage(row.name+'地区不支持申报',1)
      //   return
      // }
      let param = {
        taskName: 'tax-cwyear-sb',
        comIds: [row.comId],
        period:this.listQuery.year
      }
      this.loading = true
      sendTask(param).then(res => {
        this.loading = false
        if(res.data.msg == 'success') {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          // this.$queueMessage(res.data.data.queue)
        }
      })
    },
    // 汇算清缴
    // 采集
    yearCollect(row){
      if(row.district != 'zhejiang' && row.district != 'guangdong' && row.district != 'tianjin' && row.district != 'hubei' && row.loginMethod == '新版登录' && row.yzmType == 1 && row.keepStatus != 1){
        this.$qzfMessage('未授权登录，请先授权再发起任务！',1)
        return
      }
      // let code = row.districtCode.substring(0, 2)
      // if(!this.taskAddress.includes(code)){
      //   this.$qzfMessage(row.name+'地区不支持采集',1)
      //   return
      // }
      let param = {
        taskName: 'tax-year-cj',
        comIds: [row.comId],
        period:this.listQuery.year
      }
      this.loading = true
      sendTask(param).then(res => {
        this.loading = false

        if(res.data.msg == 'success') {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)
        }
      })
    },
    // 采集完查看
    checkTable(row){
      this.listQuery.comName=row.name
      this.$refs.checkYearTable.getList(row)
    },
    // 取数
    yearAcess(row){
      let param = {
        type: 2,
        comIds: [row.comId],
        period:this.listQuery.year
      }
      this.loading = true
      yearSave(param).then(res => {
        this.loading = false
        if(res.data.msg == 'success'){
          this.$message({
            message: "取数成功",
            type: "success"
          });
        }
        this.getList()
      });
    },
    // 取数完查看
    checkQysdYear(row){
      this.listQuery.comName=row.name
      this.$refs.checkQysdYear.getList(row)
    },
    // 申报
    yearDeclare(row){
      if(row.district != 'zhejiang' && row.district != 'guangdong' && row.district != 'tianjin' && row.district != 'hubei' && row.loginMethod == '新版登录' && row.yzmType == 1 && row.keepStatus != 1){
        this.$qzfMessage('未授权登录，请先授权再发起任务！',1)
        return
      }
      // let code = row.districtCode.substring(0, 2)
      // if(!this.taskAddress.includes(code)){
      //   this.$qzfMessage(row.name+'地区不支持申报',1)
      //   return
      // }
      let param = {
        taskName: 'tax-year-sb',
        comIds: [row.comId],
        period:this.listQuery.year
      }
      this.loading = true
      sendTask(param).then(res => {
        this.loading = false

        if(res.data.msg == 'success') {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)
        }
      })
    },
    //扣款
    yearKk(row){
      if(row.district != 'zhejiang' && row.district != 'guangdong' && row.district != 'tianjin' && row.district != 'hubei' && row.loginMethod == '新版登录' && row.yzmType == 1 && row.keepStatus != 1){
        this.$qzfMessage('未授权登录，请先授权再发起任务！',1)
        return
      }
      // let code = row.districtCode.substring(0, 2)
      // if(!this.taskAddress.includes(code)){
      //   this.$qzfMessage(row.name+'地区不支持扣款',1)
      //   return
      // }
      let param = {
        taskName: 'tax-year-kk',
        comIds: [row.comId],
        period:this.listQuery.year
      }
      this.loading = true
      sendTask(param).then(res => {
        this.loading = false
        if(res.data.msg == 'success') {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)
        }
      })
    },
    //批量操作
    getIds(){
      let arr = []
      this.sels.map(v=>{
        arr.push(v.comId)
      })
      this.ids = arr
    },
    handleSelectionChange(val){
      if(this.allChecked && this.list.length != this.tableData.length){
        let currentIndex = 0
        this.list.map((v,i2)=>{
          val.map(e=>{
            if(e.comId == v.comId){
              currentIndex = i2
            }
          })
        })
        let arr = this.tableData.slice(currentIndex+1,this.tableData.length)
        let newArr = [...val,...arr]
        this.sels = newArr
      }else{
        this.sels = val;
      }
      this.getIds()
    },
    handleSelectionChangeAll(e){
      if(e.length != 0){
        this.sels = this.tableData
        this.getIds()
        this.allChecked = true
      }else{
        this.sels = []
        this.ids = []
        this.allChecked = false
      }
    },
    //批量取数
    batchCwbbQs(){
      if(this.sels.length == 0){
        this.$qzfMessage('至少选择一条公司',1)
        return
      }
      let param = {
        type: 1,
        comIds: this.ids,
        period:this.listQuery.year
      }
      this.loading = true
      yearSave(param).then(res => {
        this.loading = false
        if(res.data.msg == 'success'){
          this.$qzfMessage('取数成功')
        }
        this.getList()
      });
    },
    //批量申报
    batchCwbbSb(){
      if(this.sels.length == 0){
        this.$qzfMessage('至少选择一条公司',1)
        return
      }
      let loginStr = ''
      loginStr = this.filterLoginStatus()
      if(loginStr){
        this.$qzfMessage(loginStr,1)
        return
      }
      // let cityStr = ''
      // this.sels.map(v=>{
      //   let code = v.districtCode.substring(0, 2)
      //   if(!this.taskAddress.includes(code)){
      //     cityStr = v.name+'地区不支持申报'
      //     return
      //   }
      // })
      // if(cityStr){
      //   this.$qzfMessage(cityStr,1)
      //   return
      // }
      let str = ''
      this.sels.map(v=>{
        if(v.taskStatus1 != 3){
          str = v.name + '财务报表未取数'
          return
        }
      })
      if(str){
        this.$qzfMessage(str,1)
        return
      }
      let param = {
        taskName: 'tax-cwyear-sb',
        comIds: this.ids,
        period:this.listQuery.year
      }
      this.loading = true
      sendTask(param).then(res => {
        this.loading = false
        if(res.data.msg == 'success') {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          // this.$queueMessage(res.data.data.queue)
        }
      })
    },
    //批量采集
    batchCollect(){
      if(this.sels.length == 0){
        this.$qzfMessage('至少选择一条公司',1)
        return
      }
      let loginStr = ''
      loginStr = this.filterLoginStatus()
      if(loginStr){
        this.$qzfMessage(loginStr,1)
        return
      }
      // let cityStr = ''
      // this.sels.map(v=>{
      //   let code = v.districtCode.substring(0, 2)
      //   if(!this.taskAddress.includes(code)){
      //     cityStr = v.name+'地区不支持采集'
      //     return
      //   }
      // })
      // if(cityStr){
      //   this.$qzfMessage(cityStr,1)
      //   return
      // }
      let str = ''
      this.sels.map(v=>{
        if(v.taskStatus1 != 3){
          str = v.name + '财务报表未取数' ///取数状态过滤
          return
        }
      })
      if(str){
        this.$qzfMessage(str,1)
        return
      }
      let sbStr = ''
      this.sels.map(v=>{
        if(v.taskStatus2 != 3 || (v.taskStatus2 == 3 && v.bussinessStatus2 == 3)){
          sbStr = v.name + '财务报表未申报' ///申报状态过滤
          return
        }
      })
      if(sbStr){
        this.$qzfMessage(sbStr,1)
        return
      }
      let param = {
        taskName: 'tax-year-cj',
        comIds: this.ids,
        period:this.listQuery.year
      }
      this.loading = true
      sendTask(param).then(res => {
        this.loading = false
        if(res.data.msg == 'success') {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)
        }
      })
    },
    ///汇算清缴取数
    batchQs(){
      if(this.sels.length == 0){
        this.$qzfMessage('至少选择一条公司',1)
        return
      }
      let str = ''
      this.sels.map(v=>{
        if(v.taskStatus1 != 3){
          str = v.name + '财务报表未取数' ///取数状态过滤
          return
        }
      })
      if(str){
        this.$qzfMessage(str,1)
        return
      }
      let sbStr = ''
      this.sels.map(v=>{
        if(v.taskStatus2 != 3 || (v.taskStatus2 == 3 && v.bussinessStatus2 == 3)){
          sbStr = v.name + '财务报表未申报' ///申报状态过滤
          return
        }
      })
      if(sbStr){
        this.$qzfMessage(sbStr,1)
        return
      }
      let cjStr = ''
      this.sels.map(v=>{
        if(v.taskStatus3 != 3){
          cjStr = v.name + '汇算清缴未采集' ///采集状态过滤
          return
        }
      })
      if(cjStr){
        this.$qzfMessage(cjStr,1)
        return
      }
      let param = {
        type: 2,
        comIds: this.ids,
        period:this.listQuery.year
      }
      this.loading = true
      yearSave(param).then(res => {
        this.loading = false
        if(res.data.msg == 'success'){
          this.$qzfMessage('取数成功')
        }
        this.getList()
      });
    },
    //汇算清缴申报
    batchSb(){
      if(this.sels.length == 0){
        this.$qzfMessage('至少选择一条公司',1)
        return
      }
      let loginStr = ''
      loginStr = this.filterLoginStatus()
      if(loginStr){
        this.$qzfMessage(loginStr,1)
        return
      }
      // let cityStr = ''
      // this.sels.map(v=>{
      //   let code = v.districtCode.substring(0, 2)
      //   if(!this.taskAddress.includes(code)){
      //     cityStr = v.name+'地区不支持申报'
      //     return
      //   }
      // })
      // if(cityStr){
      //   this.$qzfMessage(cityStr,1)
      //   return
      // }
      let str = ''
      this.sels.map(v=>{
        if(v.taskStatus1 != 3){
          str = v.name + '财务报表未取数' ///取数状态过滤
          return
        }
      })
      if(str){
        this.$qzfMessage(str,1)
        return
      }
      let sbStr = ''
      this.sels.map(v=>{
        if(v.taskStatus2 != 3 || (v.taskStatus2 == 3 && v.bussinessStatus2 == 3)){
          sbStr = v.name + '财务报表未申报' ///申报状态过滤
          return
        }
      })
      if(sbStr){
        this.$qzfMessage(sbStr,1)
        return
      }
      let cjStr = ''
      this.sels.map(v=>{
        if(v.taskStatus3 != 3){
          cjStr = v.name + '汇算清缴未采集' ///采集状态过滤
          return
        }
      })
      if(cjStr){
        this.$qzfMessage(cjStr,1)
        return
      }
      let qsStr = ''
      this.sels.map(v=>{
        if(v.taskStatus4 != 3){
          qsStr = v.name + '汇算清缴未取数' ///取数状态过滤
          return
        }
      })
      if(qsStr){
        this.$qzfMessage(qsStr,1)
        return
      }
      let param = {
        taskName: 'tax-year-sb',
        comIds: this.ids,
        period:this.listQuery.year
      }
      this.loading = true
      sendTask(param).then(res => {
        this.loading = false
        if(res.data.msg == 'success') {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)
        }
      })
    },
    batchKk(){
      if(this.sels.length == 0){
        this.$qzfMessage('至少选择一条公司',1)
        return
      }
      let loginStr = ''
      loginStr = this.filterLoginStatus()
      if(loginStr){
        this.$qzfMessage(loginStr,1)
        return
      }
      // let cityStr = ''
      // this.sels.map(v=>{
      //   let code = v.districtCode.substring(0, 2)
      //   if(!this.taskAddress.includes(code)){
      //     cityStr = v.name+'地区不支持扣款'
      //     return
      //   }
      // })
      // if(cityStr){
      //   this.$qzfMessage(cityStr,1)
      //   return
      // }
      let str = ''
      this.sels.map(v=>{
        if(v.taskStatus5 != 3){
          str = v.name + '汇算清缴未申报' ///申报状态过滤
          return
        }
      })
      if(str){
        this.$qzfMessage(str,1)
        return
      }
      let param = {
        taskName: 'tax-year-kk',
        comIds: this.ids,
        period:this.listQuery.year
      }
      this.loading = true
      sendTask(param).then(res => {
        this.loading = false
        if(res.data.msg == 'success') {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)
        }
      })
    },
    //统一过滤授权状态
    filterLoginStatus(){
      let str = ''
      this.sels.map(v=>{
        if(v.district != 'zhejiang' && v.district != 'guangdong' && v.district != 'tianjin' && v.district != 'hubei' && v.loginMethod == '新版登录' && v.yzmType == 1 && v.keepStatus != 1){
          str = v.name + '未授权登录，请先授权再发起任务！'
          return
        }
      })
      return str
    },
    //查看第二步图片
    openImage2(row,index){
      let imageAll = []
      this.list.map(res=>{
        let arr = []
        if(res.image2.match(',')){
          let imgUrls = res.image2.split(',')
          imgUrls.map(item=>{
            if(item){
              arr.push({
                img:'https://file.listensoft.net' + item + "?" + Math.random(10),
                tableName:""
              })
            }
          })
        }else{
          if(res.image2){
            arr.push({
              img:'https://file.listensoft.net' + res.image2 + "?" + Math.random(10),
              tableName:""
            })
          }
        }
        let url = {
          name:res.name,
          url:arr,
        }
        imageAll.push(url)
      })
      this.$refs.sbImage.dialog(imageAll, index,0)
    },
    //查看第五步图片
    openImage5(row,index){
      let imageAll = []
      this.list.map(res=>{
        let arr = []
        if(res.image5.match(',')){
          let imgUrls = res.image5.split(',')
          imgUrls.map(item=>{
            if(item){
              arr.push({
                img:'https://file.listensoft.net' + item + "?" + Math.random(10),
                tableName:""
              })
            }
          })
        }else{
          if(res.image5){
            arr.push({
              img:'https://file.listensoft.net' + res.image5 + "?" + Math.random(10),
              tableName:""
            })
          }
        }
        let url = {
          name:res.name,
          url:arr,
        }
        imageAll.push(url)
      })
      this.$refs.sbImage.dialog(imageAll, index,0)
    },
    //查看第六步图片
    openImage6(row,index){
      let imageAll = []
      this.list.map(res=>{
        let arr = []
        if(res.image6.match(',')){
          let imgUrls = res.image6.split(',')
          imgUrls.map(item=>{
            if(item){
              arr.push({
                img:'https://file.listensoft.net' + item + "?" + Math.random(10),
                tableName:""
              })
            }
          })
        }else{
          if(res.image6){
            arr.push({
              img:'https://file.listensoft.net' + res.image6 + "?" + Math.random(10),
              tableName:""
            })
          }
        }
        let url = {
          name:res.name,
          url:arr,
        }
        imageAll.push(url)
      })
      this.$refs.sbImage.dialog(imageAll, index,0)
    },
  },
};
</script>

<style lang="scss" scoped>
.main{
  width: 98%;
  margin: 0 auto;
  margin-top: 10px;
}
.top_btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.item_icon{
  display: inline-block;
  cursor: pointer;
  i{
    display: inline-block;
    font-size: 13px;
    margin-right: 4px;
  }
  p{
    display: inline-block;
    font-size: 13px;
  }
}
.content_data {
  width: 98%;
  margin: 10px auto;
  background-color: #d8ecff;
}
.each {
  width: 15%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
}

.icon_bg {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #aedcfb;
  text-align: center;
  line-height: 35px;
}
:deep(.el-progress-circle) {
  width: 40px !important;
  height: 40px !important;
}
:deep(.el-progress__text) {
  font-size: 13px !important;
  min-width: 40px !important;
}
.count {
  display: inline-block;
  margin-left: 10px;
  color: #999;
}
.count::before {
  content: "";
  background: #c4c3c3;
  display: inline-block;
  width: 1px;
  height: 12px;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
}
.operation_btn{
  margin-left: 5px;
  padding: 5px 8px;
}
.hint{
  margin-left: 5px;
  font-size: 14px;
  color: red;
  .icon-gantanhao1{
    font-size: 13px;
    margin-right:3px;
    color: red;
  }
}
</style>