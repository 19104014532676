<template>
  <div>
    <el-dialog :close-on-click-modal="false"
      v-model="dialogFormVisible"
      :title="name"
      width="90%"
      destroy-on-close
      >
      <el-table :data="tableData" style="width: 100%" border v-loading="loading" :height="contentStyleObj">
        <el-table-column label="报表名称">
          <template #default="scope">
            <span style="cursor: pointer;" @click="handleEdit(scope.row.project)">{{scope.row.project}}({{ scope.row.type }})</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作" width="80" align="center"> 
          <template #default="scope">
            <el-checkbox v-model="scope.row.status"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作" width="150" align="center"> 
          <template #default="scope">
            <el-button @click="handleEdit(scope.row.project)" type="primary"  size='mini' plain>编辑</el-button>
            <el-button @click="daochu(scope.row)" type="primary" :loading="scope.row.daochuloading" size='mini' plain>导出</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="save_box" style="text-align: right;">
        <el-button type="primary" size="small" @click="saveList()" v-if="tableData != ''" style="margin-top:20px">保存</el-button>
      </div>
    </el-dialog>

    <A000000Dialog :comId="this.comId" :startAccountPeriod="this.yearTime" tableYearName="year_a000000" :name="name" ref="A000000" ></A000000Dialog>
    <A100000 :comId="this.comId" :startAccountPeriod="this.yearTime" tableYearName="year_a100000" :name="name" ref="A100000"></A100000>
    <A101010 :comId="this.comId" :startAccountPeriod="this.yearTime" tableYearName="year_a101010" :name="name" ref="A101010"></A101010>
    <A102010 :comId="this.comId" :startAccountPeriod="this.yearTime" tableYearName="year_a102010" :name="name" ref="A102010"></A102010>
    <A104000 :comId="this.comId" :startAccountPeriod="this.yearTime" tableYearName="year_a104000" :name="name" ref="A104000"></A104000>
    <A105000 :comId="this.comId" :startAccountPeriod="this.yearTime" tableYearName="year_a105000" :name="name" ref="A105000"></A105000>
    <A105010 :comId="this.comId" :startAccountPeriod="this.yearTime" tableYearName="year_a105010" :name="name" ref="A105010"></A105010>
    <A105050 :comId="this.comId" :startAccountPeriod="this.yearTime" tableYearName="year_a105050" :name="name" ref="A105050"></A105050>
    <A105060 :comId="this.comId" :startAccountPeriod="this.yearTime" tableYearName="year_a105060" :name="name" ref="A105060"></A105060>
    <A105080 :comId="this.comId" :startAccountPeriod="this.yearTime" tableYearName="year_a105080" :name="name" ref="A105080"></A105080>
    <A106000Dialog :comId="this.comId" :startAccountPeriod="this.yearTime" tableYearName="year_a106000" :name="name" ref="A106000"></A106000Dialog>
    <A107010 :comId="this.comId" :startAccountPeriod="this.yearTime" tableYearName="year_a107010" :name="name" ref="A107010"></A107010>

    <A107020 :comId="this.comId" :startAccountPeriod="this.yearTime" tableYearName="year_a107020" :name="name" ref="A107020"></A107020>

    <A107040 :comId="this.comId" :startAccountPeriod="this.yearTime" tableYearName="year_a107040" :name="name" ref="A107040"></A107040>
    <A107041 :comId="this.comId" :startAccountPeriod="this.yearTime" tableYearName="year_a107041" :name="name" ref="A107041"></A107041>
    <A107012 :comId="this.comId" :startAccountPeriod="this.yearTime" tableYearName="year_a107012" :name="name" ref="A107012"></A107012>

    
  </div>
</template>

<script>
import { yearInfo ,yearEdit,exYearInfo } from "@/api/year";

import A000000Dialog from "../../report/yearTax/A000000Dialog.vue"
import A100000 from "../../report/yearTax/A100000.vue"
import A101010 from "../../report/yearTax/A101010.vue"
import A102010 from "../../report/yearTax/A102010.vue"
import A104000 from "../../report/yearTax/A104000.vue"
import A105000 from "../../report/yearTax/A105000.vue"
import A105050 from "../../report/yearTax/A105050.vue"
import A105060 from "../../report/yearTax/A105060.vue"
import A105080 from "../../report/yearTax/A105080.vue"
import A106000Dialog from "../../report/yearTax/A106000Dialog.vue"
import A107010 from "../../report/yearTax/A107010.vue"

import A107020 from "../../report/yearTax/A107020.vue"

import A107040 from "../../report/yearTax/A107040.vue"
import A107041 from "../../report/yearTax/A107041.vue"

import A107012 from "../../report/yearTax/A107012.vue";
import A105010 from "../../report/yearTax/A105010.vue";
export default {
  name: 'checkQysdYear',
  components:{ A000000Dialog,A100000,A101010,A102010,A104000,A105000,A105050,A105060,A105080,A106000Dialog,A107010,A107020,A107040,A107041,A107012 ,A105010},
  props: {
    yearTime:{
      default:'',
      type:String
    },
    name:{
      default:'',
      type:String
    },
  },
  data() {
    return {
      dialogFormVisible:false,
      comId:0,
      tableData:[],
      loading:false,
      contentStyleObj:{},
      daochuloading:false,
    };
  },

  mounted() {
    this.contentStyleObj = this.$getHeight(230)
  },

  methods: {
    getList(e){
      this.loading = true
      this.comId = e.comId
      this.dialogFormVisible =true
      yearInfo({comId:e.comId,period:this.yearTime,tableName:'year_select'}).then(res => {
        this.loading = false
        if(res.data.msg == '未生成数据'){
          this.$message({
            message: "暂未生成报表，请生成报表",
            type: "warning"
          });
          this.tableData = []
        } else{
          this.tableData = res.data.data
          this.tableData.map(v=>{
            if(v.status == 1){
              v.status = true
            }
          })
        }
      });
    },
    // 跳转页面
    handleEdit(e){
      if (e == "企业所得税年度纳税申报基础信息表") {
        this.$refs.A000000.getParams()
      } else if (e == "中华人民共和国企业所得税年度纳税申报表（A类）") {
        this.$refs.A100000.getParams()

      }else if (e == "一般企业收入明细表") {
        this.$refs.A101010.getParams()

      }else if (e == "一般企业成本支出明细表") {
        this.$refs.A102010.getParams()

      }else if (e == "期间费用明细表") {
        this.$refs.A104000.getParams()

      }  else if (e == "纳税调整项目明细表") {
        this.$refs.A105000.getParams()

      }  else if (e == "视同销售和房地产开发企业特定业务纳税调整明细表") {
        this.$refs.A105010.getParams()

      }else if (e == "职工薪酬支出及纳税调整明细表") {
        this.$refs.A105050.getParams()

      } else if (e == "广告费和业务宣传费等跨年度纳税调整明细表") {
        this.$refs.A105060.getParams()

      } else if (e == "资产折旧、摊销及纳税调整明细表") {
        this.$refs.A105080.getParams()

      }  else if (e == "企业所得税弥补亏损明细表") {
        this.$refs.A106000.getParams()

      }else if (e == "免税、减计收入及加计扣除优惠明细表") {
        this.$refs.A107010.getParams()

      }else if (e == "研发费用加计扣除优惠明细表") {
        this.$refs.A107012.getParams()

      }else if (e == "所得减免优惠明细表") {
        this.$refs.A107020.getParams()

      }else if (e == "减免所得税优惠明细表") {
        this.$refs.A107040.getParams()

      }else if (e == "高新技术企业优惠情况及明细表") {
        this.$refs.A107041.getParams()

      }
    },
    daochu(e){
      e.daochuloading = true
      let type = e.tableName
      exYearInfo({tableName:type,comId:this.comId,period:this.yearTime,}).then(res => {
        window.open(res.data.data.url)
        e.daochuloading = false;
      })
    },

    // 点击保存
    saveList(){
      this.tableData.map(v=>{
        if(v.status == true){
          v.status = 1
        } else if(v.status == false){
          v.status = 0
        }
      })
      this.fullscreenLoading = true
      yearEdit({tableName:'year_select',items:this.tableData}).then(res => {
        if(res.data.msg == 'success'){
          this.$message({
            message: "保存成功",
            type: "success"
          });
          let pram = {
            comId:this.comId
          }
          this.getList(pram)
        }
        this.fullscreenLoading = false
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>