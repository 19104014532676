<template>
  <div style="position: relative;">
    <div style="text-align: right;position: absolute;right: 18px;top:8px;z-index:99">
      <span style="font-size: 14px;">账期选择：</span><qzf-search-period v-model:period="listQuery.period" @success="getList" style="margin-right:0"></qzf-search-period>
      <selecttaxtype v-model:type="listQuery.type"  @success="getList"></selecttaxtype>
      <selectuser code="bookkeeping" v-model:userId="listQuery.userId"  @success="getList"  style="margin-left: 0;width: 170px;"></selectuser>
      <selectcity v-model:citys="listQuery.districtCode" style="margin: 0;" @success="getList"></selectcity>
    </div>
    
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <div style="width:76%;float:left;margin-bottom:10px">
        <el-button icon="RefreshRight" size="small" style="margin-right: 5px;" @click="getList()"></el-button>
        <el-input size="small" placeholder="请输入公司名称/报税手机号/编号" v-model.trim="listQuery.name" style="width:200px" @keyup.enter="getList" clearable></el-input>
        <el-button size="small" type="primary" @click="getList" style="margin-right:5px">
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel">
        <el-form style="width:350px" label-width="110px" class="styleForm" size="small">
          <el-form-item label="会计准则:"> 
            <el-select v-model="listQuery.accountSystem" placeholder="请选择会计准则" style="width:160px;" clearable>
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="公司类型："> 
            <el-select v-model="listQuery.companyType" size="small" placeholder="请选择公司类型"  filterable clearable style="width:160px">
              <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="电子账生成状态:"> 
            <el-select v-model="listQuery.dzzStatus" placeholder="请选择电子账生成状态" style="width:160px;">
              <el-option v-for="item in zbOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="账簿生成状态:"> 
            <el-select v-model="listQuery.dzzbStatus" placeholder="请选择账簿生成状态" style="width:160px;">
              <el-option v-for="item in zbOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="装订状态:"> 
            <el-select v-model="listQuery.bindingStatus" placeholder="装订状态" style="width:160px;">
              <el-option label="全部" value=""></el-option>
              <el-option label="未装订" value="0"></el-option>
              <el-option label="已装订" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="公司名称:"> 
            <el-input
              v-model="listQuery.namequery"
              style="width: 160px"
              :rows="2"
              type="textarea"
              placeholder="请输入公司名称"
            />
          </el-form-item>
        </el-form>
      </search>
        
      </div>
      <el-tab-pane label="税款公司" name="first">
        <eletricTable ref="clientList" :listQuery="listQuery" :tabName="tabName" v-if="tabName == 'first'"/>
      </el-tab-pane>
      <el-tab-pane label="零申报公司" name="second">
        <eletricTable ref="clientList" :listQuery="listQuery" :tabName="tabName" v-if="tabName == 'second'"/>
      </el-tab-pane>
      <el-tab-pane label="停报公司" name="third">
        <eletricTable ref="clientList" :listQuery="listQuery" :tabName="tabName" v-if="tabName == 'third'"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import eletricTable from "./components/eletricTable.vue";
import selecttaxtype from "@/components/Screening/selecttaxtype";
import { currentAccountPeriod } from '@/utils'
import selectcity from "@/components/Screening/selectcity";
import { ACCOUNT_SYSTEM_OPTION,COMPANY_TYPE_OPTION } from '@/utils/commonData.js'
export default {
  name: "eletric-book",
  components: {
    eletricTable,
    selecttaxtype,
    selectcity
  },
  props: {},
  data() {
    return {
      typeOptions: COMPANY_TYPE_OPTION,
      activeName: "first",
      listQuery: {
        page: 1,
        limit: 20,
        accountingType: 2,
        status: "2",
        companyType: "",
        period: currentAccountPeriod(),
        dzzStatus:'-1',
        dzzbStatus:'-1',
        namequery:'',
      },
      tabName:"first",
      options: ACCOUNT_SYSTEM_OPTION,
      zbOptions:[
        {
          label:"全部",
          value:"-1"
        },
        {
          label:"未生成",
          value:"0"
        },
        {
          label:"生成中",
          value:"1"
        },
        {
          label:"已生成",
          value:"2"
        },
        {
          label:"生成失败",
          value:"3"
        },
      ]
    };
  },
  methods: {
    getList() {
      if(this.listQuery.namequery){
        this.listQuery.names = this.listQuery.namequery.split('\n')
      }
      this.$nextTick(() => {
        this.$refs.clientList.getList()
      });
    },
    handleClick(tab) {
      this.listQuery.page = 1;
      this.listQuery.limit = 20;
      if (tab.props.name == "first") {
        this.tabName = tab.props.name
        this.listQuery.accountingType = 2;
        this.listQuery.status = "2";
      } else if (tab.props.name == "second") {
        this.tabName = tab.props.name
        this.listQuery.accountingType = 1;
        this.listQuery.status = "2";
      } else if (tab.props.name == "third") {
        this.tabName = tab.props.name
        this.listQuery.accountingType = null;
        this.listQuery.status = "3";
      }
    },
    cancel(){
      let originLimit = this.listQuery.limit;
      this.listQuery = {
        page: 1,
        limit: originLimit,
        accountingType: 2,
        status: "2",
        companyType: "",
        period: currentAccountPeriod(),
        dzzStatus:'-1',
        dzzbStatus:'-1',
        namequery:'',
      }
      this.listQuery.names = []
      this.getList()
    }
  },
};
</script>

<style scoped lang="scss">
.styleForm{
  .el-select{
    margin: 0px;
  }
}
</style>
