<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogVisible"
    title="工资表打印"
    width="700px"
  >
    <el-form size="small" label-width="100px">
      <el-form-item label="纸张类型:">
        <el-radio label="A4-S" border v-model="paperType">A4纸竖向</el-radio>
        <el-radio label="A5-H" border v-model="paperType">凭证纸</el-radio>
      </el-form-item>
      <el-form-item label="所属期:">
        <el-date-picker
          v-model="startPeriod"
          type="month"
          placeholder="选择月"
          value-format="YYYYMM"
        >
        </el-date-picker>
        --
        <el-date-picker
          v-model="endPeriod"
          type="month"
          placeholder="选择月"
          value-format="YYYYMM"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="左偏移(mm)：">
        <el-input v-model.number="left" placeholder="请输入左偏移" style="width:450px"></el-input>
      </el-form-item>
      <el-form-item label="右偏移(mm)：">
        <el-input v-model.number="right" placeholder="请输入右偏移" style="width:450px"></el-input>
      </el-form-item>
      <el-form-item label="字体：">
        <el-radio v-model="ttf" :label="1">宋体</el-radio>
        <el-radio v-model="ttf" :label="2">微软雅黑</el-radio>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="dayin" :loading="loading"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { bulkPrintMq } from "@/api/printSet";
export default {
  name: "eleEmployeePrint",
  components: {},
  props: {
    period: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogVisible: false,
      startPeriod: "",
      endPeriod: "",
      paperType: "A4-S",
      left: 0,
      right: 0,
      ttf: 1,
      loading: false,
      comIds: [],
    };
  },
  methods: {
    init(arr) {
      this.comIds = arr;
      this.dialogVisible = true;
    },
    dayin() {
      if (this.left > 50) {
        this.$qzfMessage("左偏移最大为50！", 1);
        return;
      }
      if (this.right > 25) {
        this.$qzfMessage("右偏移最大为25！", 1);
        return;
      }
      this.loading = true;
      let dayinParam = {
        comIds: this.comIds,
        startPeriod: this.startPeriod,
        endPeriod: this.endPeriod,
        bookNames: ["salray"],
        type: "salary",
        zip: 1,
        mqType: "salary",
        ttf: this.ttf,
        left: this.left*1,
        right: this.right*1,
        period: this.period,
        paperType: this.paperType,
      };
      bulkPrintMq(dayinParam).then((res) => {
        this.dialogVisible = false;
        this.loading = false;
        if (res.data.data.msg) {
          this.$qzfMessage(res.data.data.msg);
        } else {
          window.open(res.data.data.path);
        }
      });
    },
  },
};
</script>

<style scoped lang="less"></style>
